const socialLinks = [
  // {
  //   id: 1,
  //   icon: <i className="fab fa-slack-hash" />,
  //   url: "https://linktr.ee/moggyfusiondao",
  // },
  {
    id: 2,
    icon: <i className="fab fa-edge" />,
    url: "https://crewmode.xyz/",
  },
  // {
  //   id: 3,
  //   icon: <i className="fab fa-facebook" />,
  //   url: "https://www.facebook.com/profile.php?id=100084349120174",
  // },
  {
    id: 4,
    icon: <i className="fa-twitter fab" />,
    url: "https://twitter.com/crewmodexyz",
  },
  // {
  //   id: 5,
  //   icon: <i className="fab fa-youtube" />,
  //   url: "https://www.youtube.com/channel/UCTzFa7zzyMMeyMHIdsb60nw",
  // },
  // {
  //   id: 6,
  //   icon: <i className="fa-discord fab" />,
  //   url: "https://discord.gg/hBEgtDmyd5",
  // },
  // {
  //   id: 7,
  //   icon: <i className="fab fa-rocketchat" />,
  //   url: "https://discord.com/channels/979415722528751688/1005130319965470952",
  // },
  

  
];

// import Banner from "./component/Banner";

const AppFooter = () => {
  return (
    <div className="container mx-auto">


      <div className="border-gray-50 border-t-2 dark:border-gray-700 mt-20 pb-3 pt-20 sm:pt-30">
        {/* Footer social links */}

        
<div class="w-full p-4 text-center bg-white border rounded-lg shadow-md sm:p-8 dark:bg-ternary-dark dark:border-gray-700">
    {/* <h5 class="mb-2 text-3xl font-bold text-gray-900 dark:text-white">Work fast from anywhere</h5>
    <p class="mb-5 text-base text-gray-500 sm:text-lg dark:text-gray-400">Stay up to date and move work forward with AURORA NFT. On JIBChain</p> */}
        <h5 class="mb-2 text-3xl font-bold text-gray-900 dark:text-white">Crew Mode NFT</h5>
    <p class="mb-5 text-base text-gray-500 sm:text-lg dark:text-gray-400">For everyone, fairly and equally. They provide access and stimulate engagement across the Mode Network. <br></br> Join the movement!</p>
    <div class="items-center justify-center space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
        
        {/* <a target="_blank" href="https://discord.com/channels/979415722528751688/1005130319965470952" class="w-full sm:w-auto bg-gray-800 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-300 text-white rounded-lg inline-flex items-center justify-center px-4 py-2.5 dark:border-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700">
            <div class="text-left">
                <div class="mb-1 text-xs">Only ERC-20 verify code</div>
                <div class="-mt-1 text-sm     font-sans">Import your Token</div>
            </div>
        </a> */}

       {/* <a target="_blank" href="https://discord.com/channels/979415722528751688/1005130319965470952" class="w-full sm:w-auto bg-gray-800 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-300 text-white rounded-lg inline-flex items-center justify-center px-4 py-2.5 dark:border-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700">
            <div class="text-left">
                <div class="mb-1 text-xs">Only ERC-721 start id 1+</div>
                <div class="-mt-1 text-sm     font-sans">Import your NFT</div>
            </div>
        </a> */}

        {/* <a target="_blank" href="https://discord.com/channels/979415722528751688/1005130319965470952" class="w-full sm:w-auto bg-gray-800 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-300 text-white rounded-lg inline-flex items-center justify-center px-4 py-2.5 dark:border-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700">
            <div class="text-left">
                <div class="mb-1 text-xs">Deploy smartcontract with us easily</div>
                <div class="-mt-1 text-sm     font-sans">Create your NFT</div>
            </div>
        </a> */}

        <a target="_blank" href="https://ref.mode.network/A7eUQi" class="w-full sm:w-auto bg-gray-800 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-300 text-white rounded-lg inline-flex items-center justify-center px-4 py-2.5 dark:border-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700">
            <div class="text-left">
                <div class="mb-1 text-xs">Bridge on Mode</div>
                <div class="-mt-1 text-sm     font-sans">Mode Network</div>
            </div>
        </a>

        <a target="_blank" href="https://github.com/crewmodexyz" class="w-full sm:w-auto bg-gray-800 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-300 text-white rounded-lg inline-flex items-center justify-center px-4 py-2.5 dark:border-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700">
            <div class="text-left">
                <div class="mb-1 text-xs">Deploy NFTs</div>
                <div class="-mt-1 text-sm     font-sans">Create your NFT</div>
            </div>
        </a>

        <a target="_blank" href="https://twitter.com/crewmodexyz" class="w-full sm:w-auto bg-gray-800 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-300 text-white rounded-lg inline-flex items-center justify-center px-4 py-2.5 dark:border-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700">
            <div class="text-left">
                <div class="mb-1 text-xs">List NFTs</div>
                <div class="-mt-1 text-sm     font-sans">is Here</div>
            </div>
        </a>

        <a target="_blank" href="https://twitter.com/crewmodexyz" class="w-full sm:w-auto bg-gray-800 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-300 text-white rounded-lg inline-flex items-center justify-center px-4 py-2.5 dark:border-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700">
            {/* <svg class="mr-3 w-7 h-7" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="google-play" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M325.3 234.3L104.6 13l280.8 161.2-60.1 60.1zM47 0C34 6.8 25.3 19.2 25.3 35.3v441.3c0 16.1 8.7 28.5 21.7 35.3l256.6-256L47 0zm425.2 225.6l-58.9-34.1-65.7 64.5 65.7 64.5 60.1-34.1c18-14.3 18-46.5-1.2-60.8zM104.6 499l280.8-161.2-60.1-60.1L104.6 499z"></path></svg> */}
            <div class="text-left">
                <div class="mb-1 text-xs">Get in on</div>
                <div class="-mt-1 text-sm     font-sans">Support & Partner</div>
            </div>
        </a>

    </div>
</div>

   <br></br>

        <div className="flex flex-col font-general-regular items-center justify-center mb-12 sm:mb-10">
          <ul className="flex gap-4 sm:gap-8">
            {socialLinks.map((link) => (
              <a
                href={link.url}
                target="__blank"
                key={link.id}
                className="bg-gray-50 cursor-pointer dark:bg-ternary-dark dark:hover:text-indigo-400 duration-300 hover:bg-gray-100 hover:text-indigo-500 p-4 rounded-lg shadow-sm text-gray-400">
                <i className="md:text-3xl sm:text-2xl text-xl">{link.icon}</i>
              </a>
            ))}
          </ul>

          <p className="dark:text-gray-500 font-bold mb-0 my-10 sm:text-xl text-ternary-dark text-xl">
          CREW MODE MARKET @2024 - <a target="__blank" href="https://twitter.com/crewmodexyz"> Powered By CREW MODE NFT</a>
          </p>
        </div>
        {/* <Banner /> */}
      </div>
    </div>
  );
};

export default AppFooter;
