import { motion } from "framer-motion";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


// Banner slide
import passport_nft from "../../assets/images/nftimgs/Qwagg Crew-gif-maker.gif"; // แบนเนอร์ สไลด์ เปลี่ยนแค่ ไฟลหลัง .png
// import spaceowl_nft from "../../assets/images/nftimgs/spaceowl_nft.png";
import soon1_nft from "../../assets/images/nftimgs/soon.png";
import soon2_nft from "../../assets/images/nftimgs/soon.png";  

import cmjib_nft from "../../assets/images/nftimgs/cmjib_nft_logo.png";

// import dragondoodle_nft from "../../assets/images/nftimgs/dragondoodle_nft.png";
import join1_nft from "../../assets/images/nftimgs/join.png";
import join_nft from "../../assets/images/nftimgs/join.png";

import jadedz_nft from "../../assets/images/nftimgs/jadedz_nft.png";
import join3_nft from "../../assets/images/nftimgs/join.png";
import join4_nft from "../../assets/images/nftimgs/join.png";
import join5_nft from "../../assets/images/nftimgs/join.png";
import join6_nft from "../../assets/images/nftimgs/join.png";
import join7_nft from "../../assets/images/nftimgs/join.png";
import join8_nft from "../../assets/images/nftimgs/join.png";
import join9_nft from "../../assets/images/nftimgs/join.png";
import join10_nft from "../../assets/images/nftimgs/join.png";
import join11_nft from "../../assets/images/nftimgs/join.png";
import join12_nft from "../../assets/images/nftimgs/join.png";
import join13_nft from "../../assets/images/nftimgs/join.png";
import join14_nft from "../../assets/images/nftimgs/join.png";

import doodcat_nft from "../../assets/images/nftimgs/doodcat_nft.png";
import deddood_nft from "../../assets/images/nftimgs/deddood_nft.png";
import royalrat_nft from "../../assets/images/nftimgs/royalrat_nft.png";
import classicalcat_nft from "../../assets/images/nftimgs/classicalcat_nft.png";
import alien_nft from "../../assets/images/nftimgs/alien_nft.png";
import elementowl_nft from "../../assets/images/nftimgs/elementowl_nft.png";
import elementdog_nft from "../../assets/images/nftimgs/elementdog_nft.png";
import elementoctopus_nft from "../../assets/images/nftimgs/elementoctopus_nft.png";
import qaf_nft from "../../assets/images/nftimgs/qaf_nft.png";
import badbuddies_nft from "../../assets/images/nftimgs/badbuddies_nft.mp4";
import badbuddies2_nft from "../../assets/images/nftimgs/badbuddies2_nft.mp4";
import badbuddies3_nft from "../../assets/images/nftimgs/badbuddies3_nft.mp4";
import primordialplanet_nft from "../../assets/images/nftimgs/primordialplanet_nft.png";

const NftSlider = () => {
  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 1500,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1160,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          swipeToSlide: true,
        },
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          swipeToSlide: true,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 470,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 370,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: true,
        },
      },
    ],
  };

  return (
    <>
      <motion.h1
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          ease: "easeInOut",
          duration: 0.9,
          delay: 0.1,
        }}
        className="font-general-regular 
                      text-center text-ternary-dark
                      dark:text-white
                      text-3xl
                      sm:text-4xl
                      pt-20
                      font-bold
                      ">
        NFT Collections
      </motion.h1>
      <motion.section
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ ease: "easeInOut", duration: 0.9, delay: 0.2 }}
        className="flex flex-col items-center md:mt-12 mx-auto sm:flex-row sm:justify-between">
        <motion.div
          initial={{ opacity: 0, y: -180 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ ease: "easeInOut", duration: 0.9, delay: 0.2 }}
          className="float-right mt-8 sm:mt-0 sm:w-full text-right w-full">
          <Slider {...settings} className="mx-3 my-10 slider">
            
            <div className="px-3">
              <div className="bg-white dark:bg-ternary-dark rounded-xl shadow-lg slider-card">
                <div className="slider-img w-full">
                  <img
                    src={passport_nft}
                    alt=""
                    className="2xl:h-290 h-290 lg:h-186 md:h-203 object-cover rounded-t-xl sm:h-4 w-full xl:h-242"
                  />
                </div>
                <h1 className="dark:text-white font-bold p-3 text-black text-center">
                   <a href="https://crewmode.xyz/" target="_blank">
                   Crew Mode NFT
                   </a>
                </h1>
              </div>
            </div>

            {/* <div className="px-3">
              <div className="bg-white dark:bg-ternary-dark rounded-xl shadow-lg slider-card">
                <div className="slider-img w-full">
                  <img
                    src={cmjib_nft}
                    alt=""
                    className="2xl:h-290 h-290 lg:h-186 md:h-203 object-cover rounded-t-xl sm:h-4 w-full xl:h-242"
                  />
                </div>
                <h1 className="dark:text-white font-bold p-3 text-black text-center">
                CM Hexa Cat Meaw JIB JIB
                </h1>
              </div>
            </div>

            <div className="px-3">
              <div className="bg-white dark:bg-ternary-dark rounded-xl shadow-lg slider-card">
                <div className="slider-img w-full">
                  <img
                    src={jadedz_nft}
                    alt=""
                    className="2xl:h-290 h-290 lg:h-186 md:h-203 object-cover rounded-t-xl sm:h-4 w-full xl:h-242"
                  />
                </div>
                <h1 className="dark:text-white font-bold p-3 text-black text-center">
                JADEDz
                </h1>
              </div>
            </div> */}
            
            <div className="px-3">
              <div className="bg-white dark:bg-ternary-dark rounded-xl shadow-lg slider-card">
                <div className="slider-img w-full">
                  <img
                    src={soon1_nft}
                    alt=""
                    className="2xl:h-290 h-290 lg:h-186 md:h-203 object-cover rounded-t-xl sm:h-4 w-full xl:h-242"
                  />
                </div>
                <h1 className="dark:text-white font-bold p-3 text-black text-center">
                  Coming soon
                </h1>
              </div>
            </div>

            <div className="px-3">
              <div className="bg-white dark:bg-ternary-dark rounded-xl shadow-lg slider-card">
                <div className="slider-img w-full">
                  <img
                    src={soon1_nft}
                    alt=""
                    className="2xl:h-290 h-290 lg:h-186 md:h-203 object-cover rounded-t-xl sm:h-4 w-full xl:h-242"
                  />
                </div>
                <h1 className="dark:text-white font-bold p-3 text-black text-center">
                  Coming soon
                </h1>
              </div>
            </div>

            <div className="px-3">
              <div className="bg-white dark:bg-ternary-dark rounded-xl shadow-lg slider-card">
                <div className="slider-img w-full">
                  <img
                    src={soon1_nft}
                    alt=""
                    className="2xl:h-290 h-290 lg:h-186 md:h-203 object-cover rounded-t-xl sm:h-4 w-full xl:h-242"
                  />
                </div>
                <h1 className="dark:text-white font-bold p-3 text-black text-center">
                  Coming soon
                </h1>
              </div>
            </div>

            <div className="px-3">
              <div className="bg-white dark:bg-ternary-dark rounded-xl shadow-lg slider-card">
                <div className="slider-img w-full">
                  <img
                    src={soon1_nft}
                    alt=""
                    className="2xl:h-290 h-290 lg:h-186 md:h-203 object-cover rounded-t-xl sm:h-4 w-full xl:h-242"
                  />
                </div>
                <h1 className="dark:text-white font-bold p-3 text-black text-center">
                  Coming soon
                </h1>
              </div>
            </div>

            <div className="px-3">
              <div className="bg-white dark:bg-ternary-dark rounded-xl shadow-lg slider-card">
                <div className="slider-img w-full">
                  <img
                    src={soon1_nft}
                    alt=""
                    className="2xl:h-290 h-290 lg:h-186 md:h-203 object-cover rounded-t-xl sm:h-4 w-full xl:h-242"
                  />
                </div>
                <h1 className="dark:text-white font-bold p-3 text-black text-center">
                  Coming soon
                </h1>
              </div>
            </div>

            {/* <div className="px-3">
              <div className="bg-white dark:bg-ternary-dark rounded-xl shadow-lg slider-card">
                <div className="slider-img w-full">
                  <img
                    src={join1_nft}
                    alt=""
                    className="2xl:h-290 h-290 lg:h-186 md:h-203 object-cover rounded-t-xl sm:h-4 w-full xl:h-242"
                  />
                </div>
                <h1 className="dark:text-white font-bold p-3 text-black text-center">
                  Join now..
                </h1>
              </div>
            </div> */}



            {/* <div className="px-3">
              <div className="bg-white dark:bg-ternary-dark rounded-xl shadow-lg slider-card">
                <div className="slider-img w-full">
                  <img
                    src={join3_nft}
                    alt=""
                    className="2xl:h-290 h-290 lg:h-186 md:h-203 object-cover rounded-t-xl sm:h-4 w-full xl:h-242"
                  />
                </div>
                <h1 className="dark:text-white font-bold p-3 text-black text-center">
                  Join now..
                </h1>
              </div>
            </div>

            <div className="px-3">
              <div className="bg-white dark:bg-ternary-dark rounded-xl shadow-lg slider-card">
                <div className="slider-img w-full">
                  <img
                    src={join4_nft}
                    alt=""
                    className="2xl:h-290 h-290 lg:h-186 md:h-203 object-cover rounded-t-xl sm:h-4 w-full xl:h-242"
                  />
                </div>
                <h1 className="dark:text-white font-bold p-3 text-black text-center">
                  Join now..
                </h1>
              </div>
            </div>

            <div className="px-3">
              <div className="bg-white dark:bg-ternary-dark rounded-xl shadow-lg slider-card">
                <div className="slider-img w-full">
                  <img
                    src={join5_nft}
                    alt=""
                    className="2xl:h-290 h-290 lg:h-186 md:h-203 object-cover rounded-t-xl sm:h-4 w-full xl:h-242"
                  />
                </div>
                <h1 className="dark:text-white font-bold p-3 text-black text-center">
                  Join now..
                </h1>
              </div>
            </div>

            <div className="px-3">
              <div className="bg-white dark:bg-ternary-dark rounded-xl shadow-lg slider-card">
                <div className="slider-img w-full">
                  <img
                    src={join6_nft}
                    alt=""
                    className="2xl:h-290 h-290 lg:h-186 md:h-203 object-cover rounded-t-xl sm:h-4 w-full xl:h-242"
                  />
                </div>
                <h1 className="dark:text-white font-bold p-3 text-black text-center">
                  Join now..
                </h1>
              </div>
            </div>
            
            <div className="px-3">
              <div className="bg-white dark:bg-ternary-dark rounded-xl shadow-lg slider-card">
                <div className="slider-img w-full">
                  <img
                    src={join7_nft}
                    alt=""
                    className="2xl:h-290 h-290 lg:h-186 md:h-203 object-cover rounded-t-xl sm:h-4 w-full xl:h-242"
                  />
                </div>
                <h1 className="dark:text-white font-bold p-3 text-black text-center">
                  Join now..
                </h1>
              </div>
            </div>

            <div className="px-3">
              <div className="bg-white dark:bg-ternary-dark rounded-xl shadow-lg slider-card">
                <div className="slider-img w-full">
                  <img
                    src={join8_nft}
                    alt=""
                    className="2xl:h-290 h-290 lg:h-186 md:h-203 object-cover rounded-t-xl sm:h-4 w-full xl:h-242"
                  />
                </div>
                <h1 className="dark:text-white font-bold p-3 text-black text-center">
                  Join now..
                </h1>
              </div>
            </div>

            <div className="px-3">
              <div className="bg-white dark:bg-ternary-dark rounded-xl shadow-lg slider-card">
                <div className="slider-img w-full">
                  <img
                    src={join9_nft}
                    alt=""
                    className="2xl:h-290 h-290 lg:h-186 md:h-203 object-cover rounded-t-xl sm:h-4 w-full xl:h-242"
                  />
                </div>
                <h1 className="dark:text-white font-bold p-3 text-black text-center">
                  Join now..
                </h1>
              </div>
            </div>
            
            <div className="px-3">
              <div className="bg-white dark:bg-ternary-dark rounded-xl shadow-lg slider-card">
                <div className="slider-img w-full">
                  <img
                    src={join10_nft}
                    alt=""
                    className="2xl:h-290 h-290 lg:h-186 md:h-203 object-cover rounded-t-xl sm:h-4 w-full xl:h-242"
                  />
                </div>
                <h1 className="dark:text-white font-bold p-3 text-black text-center">
                  Join now..
                </h1>
              </div>
            </div>

            <div className="px-3">
              <div className="bg-white dark:bg-ternary-dark rounded-xl shadow-lg slider-card">
                <div className="slider-img w-full">
                  <img
                    src={join11_nft}
                    alt=""
                    className="2xl:h-290 h-290 lg:h-186 md:h-203 object-cover rounded-t-xl sm:h-4 w-full xl:h-242"
                  />
                </div>
                <h1 className="dark:text-white font-bold p-3 text-black text-center">
                  Join now..
                </h1>
              </div>
            </div>

            <div className="px-3">
              <div className="bg-white dark:bg-ternary-dark rounded-xl shadow-lg slider-card">
                <div className="slider-img w-full">
                  <img
                    src={join12_nft}
                    alt=""
                    className="2xl:h-290 h-290 lg:h-186 md:h-203 object-cover rounded-t-xl sm:h-4 w-full xl:h-242"
                  />
                </div>
                <h1 className="dark:text-white font-bold p-3 text-black text-center">
                  Join now..
                </h1>
              </div>
            </div>

            <div className="px-3">
              <div className="bg-white dark:bg-ternary-dark rounded-xl shadow-lg slider-card">
                <div className="slider-img w-full">
                  <img
                    src={join13_nft}
                    alt=""
                    className="2xl:h-290 h-290 lg:h-186 md:h-203 object-cover rounded-t-xl sm:h-4 w-full xl:h-242"
                  />
                </div>
                <h1 className="dark:text-white font-bold p-3 text-black text-center">
                  Join now..
                </h1>
              </div>
            </div>


            <div className="px-3">
              <div className="bg-white dark:bg-ternary-dark rounded-xl shadow-lg slider-card">
                <div className="slider-img w-full">
                  <img
                    src={join14_nft}
                    alt=""
                    className="2xl:h-290 h-290 lg:h-186 md:h-203 object-cover rounded-t-xl sm:h-4 w-full xl:h-242"
                  />
                </div>
                <h1 className="dark:text-white font-bold p-3 text-black text-center">
                  Join now..
                </h1>
              </div>
            </div> */}


          </Slider>
        </motion.div>
      </motion.section>
    </>
  );
};
export default NftSlider;
