module.exports = {
  baseURI: "https://gateway.pinata.cloud/ipfs/", // replace ipfs:// with https://gateway.pinata.cloud/ipfs/
  dedbaseURI:
    "https://gateway.pinata.cloud/ipfs/", // replace ipfs:// with https://gateway.pinata.cloud/ipfs/

  //** */

  //NFT Contract Addresss
  NFT_ADDRESS: "0xB132A2Ffb77758E46f2caFdceB007069aAf1d7C1", //abi nam - spacekittynftABI
  
  NFT_CM_JIB_ADDRESS: "xxx", //abi nam - cmjibABI
  NFT_JADEDZ_ADDRESS: "xxx", //abi nam - jadedzABI

  // Original example
  SPACEOWLSADDRESS: "", //edit name-abi from spaceowlnftABI / xxx-spaceowlnftABI
  DRAGONDOODLEADDRESS: "xxx", //edit name-abi ... xxx

  DOODNFTADDRESS: "xxx", //edit name-abi ...
  DEDNFTADDRESS: "xxx", //edit name-abi ...
  ELEMENTCATSADDRESS: "xxx", //edit name-abi ...
  ELEMENTOWLSADDRESS: "xxx", //edit name-abi ...
  ELEMENTDOGSADDRESS: "xxx", //edit name-abi ...
  ELEMENTOCTOPUSADDRESS: "xxx", //edit name-abi ...
  QAFADDRESS: "xxx", //edit name-abi ...
  ROYALRATSADDRESS: "xxx", //edit name-abi ...
  CLASSICALCATSADDRESS: "xxx", //edit name-abi ...
  ANCIENTALIENSADDRESS: "xxx", //edit name-abi ...
  PRIMORDIALPLANETOIDSADDRESS: "xxx", //edit name-abi ...
  BADBUDDIESADDRESS: "xxx", //edit name-abi ...
  BADBUDDIES2ADDRESS: "xxx", //edit name-abi ...
  BADBUDDIES3ADDRESS: "xxx", //edit name-abi ...

  //** */

  // Token Addresses to buy
  SDOODTOKENADDRESS: "xxx", //edit name-abi ...
  CARETOKENADDRESS: "xxx", //edit name-abi ...

  // Marketplace Contract Addresss
  MARKETPLACEADDRESS: "0x24DACAF185a5bA1C97E39C0D4920B904edD550dd",

  //** */

  // IPFS Addresss - json  // ipfs *No /*
  NFT_JSON:
  "https://gateway.pinata.cloud/ipfs/bafybeidkwzs6u72almueblhgf6fgf4d3v75sbvzy4n4p6lxugww7dhtbom",
  
  CM_NFT_IPFE_JSON:
  "https://gateway.pinata.cloud/ipfs/",
  JADEDz_IPFS_JSON:
  "https://gateway.pinata.cloud/ipfs/",

  DOODCATIPFSADDRESS:
    "https://gateway.pinata.cloud/ipfs/",
  DEDDOODIPFSADDRESS:
    "https://gateway.pinata.cloud/ipfs/",
  // SPACEOWLIPFSADDRESS:
  //   "https://gateway.pinata.cloud/ipfs/",
  // DRAGONDOODLEIPFSADDRESS:
  //   "https://gateway.pinata.cloud/ipfs/",
  ELEMENTOWLSIPFSADDRESS:
    "https://gateway.pinata.cloud/ipfs/",
  ELEMENTDOGSIPFSADDRESS:
    "https://gateway.pinata.cloud/ipfs/",
  ELEMENTOCTOPUSIPFSADDRESS:
    "https://gateway.pinata.cloud/ipfs/",
  QAFIPFSADDRESS:
    "https://gateway.pinata.cloud/ipfs/",
  ROYALRATSIPFSADDRESS:
    "https://gateway.pinata.cloud/ipfs/",
  CLASSICALCATSIPFSADDRESS:
    "https://gateway.pinata.cloud/ipfs/",
  ANCIENTALIENSIPFSADDRESS:
    "https://gateway.pinata.cloud/ipfs/",
  PLANETOIDSIPFSADDRESS:
    "https://gateway.pinata.cloud/ipfs/",
  BADBUDDIESIPFSADDRESS:
    "https://gateway.pinata.cloud/ipfs/",
  BADBUDDIES2IPFSADDRESS:
    "https://gateway.pinata.cloud/ipfs/",
  BADBUDDIES3IPFSADDRESS:
    "https://gateway.pinata.cloud/ipfs/",


  //** */

  // IMG IPFS Address - image  // img // .png & .jpg check!! * No / *
  NFT_IMG:
  "https://gateway.pinata.cloud/ipfs/bafybeib5mvu6kajthkwsxfyzune3b7gzgi3iwit4mw7a57z7chg2vwaafm", 
  CM_NFT_IMG:
  "https://img-cm-nft.netlify.app",
  JADEDZ_IMG:
  "https://img-jadedz-333.netlify.app",

  DOODCATIMGIPFSADDRESS:
    "https://gateway.pinata.cloud/ipfs/",
  DEDDOODIMGIPFSADDRESS:
    "https://gateway.pinata.cloud/ipfs/",
  // SPACEOWLIMGIPFSADDRESS:
  //   "https://gateway.pinata.cloud/ipfs/",
  // DRAGONDOODLEIMGIPFSADDRESS:
  //   "https://gateway.pinata.cloud/ipfs/",
  ELEMENTOWLSIMGIPFSADDRESS:
    "https://gateway.pinata.cloud/ipfs/",
  ELEMENTDOGSIMGIPFSADDRESS:
    "https://gateway.pinata.cloud/ipfs/",
  ELEMENTOCTOPUSIMGIPFSADDRESS:
    "https://gateway.pinata.cloud/ipfs/",
  QAFIMGIPFSADDRESS:
    "https://gateway.pinata.cloud/ipfs/",
  ROYALRATSIMGIPFSADDRESS:
    "https://gateway.pinata.cloud/ipfs/",
  CLASSICALCATSIMGIPFSADDRESS:
    "https://gateway.pinata.cloud/ipfs/",
  ANCIENTALIENSIMGIPFSADDRESS:
    "https://gateway.pinata.cloud/ipfs/",
  PLANETOIDSIMGIPFSADDRESS:
    "https://gateway.pinata.cloud/ipfs/",
  BADBUDDIESIMGIPFSADDRESS:
    "https://gateway.pinata.cloud/ipfs/",
  BADBUDDIES2IMGIPFSADDRESS:
    "https://gateway.pinata.cloud/ipfs/",
  BADBUDDIES3IMGIPFSADDRESS:
    "https://gateway.pinata.cloud/ipfs/",
    
};
