import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Marketplace from "./component/Marketplace/Marketplace";
import AppHeader from "./component/Navbar";
import AppFooter from "./component/Footer";
import Banner from "./component/Banner";
import Mynft from "./component/Mynft";
import Sell from "./component/Sell";
import Buy from "./component/Buy";

import { AnimatePresence } from "framer-motion";

// import { ThirdwebProvider } from "@thirdweb-dev/react";
// import { JibchainL1 } from "@thirdweb-dev/chains";

import "./App.css";
function App() {
  return (
    <AnimatePresence>
      <div
        className="bg-white dark:bg-blue-black duration-300 transition"
        style={{ minHeight: "1024px" }}>
        <Router>

        {/* <ThirdwebProvider activeChain={JibchainL1}> */}

          <AppHeader />
          <Routes>
            <Route path="/" element={<Marketplace />} />
            <Route path="/" element={<Banner />} />
            <Route path="/mynft/:contractAddress" element={<Mynft />} />
            <Route
              path="/mynft/:type/:contractAddress/:nftid"
              element={<Sell />}
            />
            <Route path="/buy/:contractAddress/:nftid" element={<Buy />} />

            {/* <Route path="*" element={<Navigate to="/" />} />  */}
          </Routes>
          <AppFooter />

          {/* </ThirdwebProvider> */}

        </Router>
        {/* <ScrollButton /> */}
      </div>
    </AnimatePresence>
  );
}

export default App;
