// eslint-disable-next-line import/prefer-default-export
export const switchSongbirdNetwork = async () => {
  const provider = window.ethereum;
  const ModeChainId = "0x868b";

  if (!provider) {
    // eslint-disable-next-line no-console
    console.log("Metamask is not installed, please install!");
  } else {
    const chainId = await provider.request({ method: "eth_chainId" });

    if (chainId === ModeChainId) {
      // eslint-disable-next-line no-console
      console.log("Bravo!, you are on the correct network");
    } else {
      // eslint-disable-next-line no-console
      console.log("oulalal, switch to the correct network");
      try {
        await provider.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: ModeChainId }],
        });
        // eslint-disable-next-line no-console
        console.log("You have succefully switched to Mode Network");
      } catch (switchError) {
        // This error code indicates that the chain has not been added to MetaMask.
        if (switchError.code === 4902) {
          // eslint-disable-next-line no-console
          console.log(
            "This network is not available in your metamask, please add it"
          );
          try {
            await provider.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: "0x868b",
                  chainName: "Mode Network",
                  rpcUrls: ["https://mainnet.mode.network"],
                  blockExplorerUrls: [
                    "https://explorer.mode.network/",
                  ],
                  nativeCurrency: {
                    symbol: "ETH", // 2-6 characters long
                    decimals: 18,
                  },
                },
              ],
            });
          } catch (addError) {
            // handle "add" error
            // eslint-disable-next-line no-console
            console.log(addError);
          }
        }
      }
    }
  }
};




// export const switchSongbirdNetwork = async () => {
//   const provider = window.ethereum;
//   const JIBChainId = "8899";

//   if (!provider) {
//     // eslint-disable-next-line no-console
//     alert("Metamask is not installed, please install!");
//     console.log("Metamask is not installed, please install!");
//   } else {
//     const chainId = await provider.request({ method: "eth_chainId" });

//     if (chainId === JIBChainId) {
//       // eslint-disable-next-line no-console
//       console.log("Bravo!, you are on the correct network");
//     } else {
//       // eslint-disable-next-line no-console
//       console.log("oulalal, switch to the correct network");
//       try {
//         await provider.request({
//           method: "wallet_switchEthereumChain",
//           params: [{ chainId: JIBChainId }],
//         });
//         // eslint-disable-next-line no-console
//         console.log("You have succefully switched to Binance Test network");
//       } catch (switchError) {
//         // This error code indicates that the chain has not been added to MetaMask.
//         if (switchError.code === 0 ) {
//           // eslint-disable-next-line no-console
//           console.log(
//             "This network is not available in your metamask, please add it"
//           );
//           try {
//             await provider.request({
//               method: "wallet_addEthereumChain",
//               params: [
//                 {
//                   chainId: "8899",
//                   chainName: "JIBCHAIN L1",
//                   rpcUrls: ["https://rpc-l1.jibchain.net"],
//                   blockExplorerUrls: [
//                     "https://exp-l1.jibchain.net",
//                   ],
//                   nativeCurrency: {
//                     symbol: "JBC", // 2-6 characters long
//                     decimals: 18,
//                   },
//                 },
//               ],
//             });
//           } catch (addError) {
//             // handle "add" error
//             // eslint-disable-next-line no-console
//             console.log(addError);
//           }
//         }
//       }
//     }
//   }
// };
